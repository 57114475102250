import { securedWrap } from '@mop/shared/utils/securedWrap';
import { categoryModel, categoryListModel } from '@/models';
import type { CategoryResponseData, CategoryListResponseData, SearchCategoryParams } from '@/types/category';

type LoadingState = {
  searchCategories: boolean;
  searchRootCategories: boolean;
  searchCategoryById: boolean;
  searchCategoryByPath: boolean;
  loading: boolean;
};

export function useMopCategories(cacheId?: string) {
  const CACHE_CATEGORY = `category-${cacheId}`;
  const CACHE_CATEGORY_LIST = `categories-${cacheId}`;
  const categoryResponseRef = useMopSSR<CategoryResponseData | null>(CACHE_CATEGORY, null);
  const categoryListResponseRef = useMopSSR<CategoryListResponseData | null>(CACHE_CATEGORY_LIST, null);
  const categoryModelRef = ref(categoryModel(categoryResponseRef.value));
  const categoryModelListRef = ref(categoryListModel(categoryListResponseRef.value));
  const { $apiBackbone } = useNuxtApp();
  const { getCachedResponse } = useClientCache();

  const loadingRef: Ref<LoadingState> = ref({
    searchCategoryById: false,
    searchRootCategories: false,
    searchCategories: false,
    searchCategoryByPath: false,
    loading: computed(() => isLoading(loadingRef)),
  });

  async function searchCategories(ids: number[]) {
    loadingRef.value.searchCategories = true;
    categoryListResponseRef.value ??= await $apiBackbone.getCategoryListByIds(ids);
    categoryModelListRef.value = categoryListModel(categoryListResponseRef.value);
    loadingRef.value.searchCategories = false;
  }

  async function searchCategoryById(id: number, params?: SearchCategoryParams) {
    loadingRef.value.searchCategoryById = true;
    categoryResponseRef.value ??= await $apiBackbone.getCategoryById(id, params);
    categoryModelRef.value = categoryModel(categoryResponseRef.value);
    loadingRef.value.searchCategoryById = false;
  }

  async function searchCategoryByPath(path: string, cacheClientResponse = false) {
    if (!path) {
      return;
    }
    loadingRef.value.searchCategoryByPath = true;
    if (cacheClientResponse) {
      categoryResponseRef.value ??= await getCachedResponse(path, () => $apiBackbone.getCategoryByPath([path]));
    } else {
      categoryResponseRef.value ??= await $apiBackbone.getCategoryByPath([path]);
    }

    categoryModelRef.value = categoryModel(categoryResponseRef.value);
    loadingRef.value.searchCategoryByPath = false;
  }

  return securedWrap({
    categoryModelRef,
    categoryModelListRef,
    searchCategoryById,
    searchCategories,
    searchCategoryByPath,
    loadingRef,
  });
}
